import React, { useCallback, useRef, useEffect } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import ReactMarkdown from 'react-markdown';
import { navigate } from 'gatsby';
import { ContentSelectors, IState } from '@pvolve/sdk/src/redux/selectors';
import Selectors from '@pvolve/sdk/src/app/selectors';
import * as Styles from 'src/styles/waiver.module.scss';
import { Button } from 'semantic-ui-react';
import { useLocation, WindowLocation } from '@reach/router';
import Actions from 'src/state/root-actions';

interface VideoPlayerLocationState {
    prevPath: string;
}

interface WaiverParams {
    workout_id: string;
}

const connector = connect((state: IState, { workout_id }: WaiverParams) => ({
    waiver: Selectors.content.page.indexBySlug(state)['app-waiver'],
    workout: ContentSelectors.workout.indexedBy.id(state)[workout_id],
}));

const Waiver = ({ dispatch, waiver, workout }: ConnectedProps<typeof connector>) => {
    const location = useLocation() as WindowLocation<VideoPlayerLocationState>;
    const waiverRef = useRef<HTMLDivElement>(null);

    const onAccept = useCallback(() => {
        const onFulfill = () => {
            dispatch(Actions.account.getAllAttrs.trigger());
        };

        dispatch(Actions.auth.acceptWaiver.trigger({ onFulfill }));
    }, [dispatch]);

    const goBack = useCallback(() => {
        const { state } = location;
        if (state?.prevPath) {
            navigate(state.prevPath);
        } else if (document.referrer) {
            if (document.referrer === window.location.href) {
                navigate(`/workouts/${workout.fields.slug}`);
            } else {
                window.location.href = document.referrer;
            }
        } else {
            navigate(`/workouts/${workout.fields.slug}`);
        }
    }, [location, workout]);

    const setHeight = () => {
        if (waiverRef.current) {
            const windowHeight = window.innerHeight;
            waiverRef.current.style.height = `${windowHeight}px`;
            waiverRef.current.style.maxHeight = `${windowHeight}px`;
        }
    };

    useEffect(() => {
        setHeight();
        window.addEventListener('resize', setHeight);
    }, []);

    return (
        <div ref={waiverRef} className={Styles.waiverContainer}>
            <div className={Styles.wrapper}>
                <div className={Styles.content}>
                    <ReactMarkdown>{waiver.fields.content}</ReactMarkdown>
                </div>
            </div>
            <div className={Styles.footer}>
                <Button secondary size="big" onClick={onAccept}>
                    Accept &amp; Continue
                </Button>

                <button onClick={goBack} className={Styles.back}>
                    Decline &amp; Go Back
                </button>
            </div>
        </div>
    );
};

export default connector(Waiver);
